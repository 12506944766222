import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import Button from "./Admin/Elements/Button";
import { ToastContainer, toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { API_URL } from "./Admin/Constants";
import { BACKEND_URL } from "./Admin/Constantsurl";

export default function EditCompetitionDetails() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [formData, setFormData] = useState({
    country: [],
  }
  );
  const [countryList, setCountryList] = useState([]);
  const [errors, setErrors] = useState({});
  const [complist, setComplist] = useState([]);
  const [quillInput, setQuillInput] = useState({ description: "" });
  const [banner, setBanner] = useState("");
  const [file, setFile] = useState(null);
  const [imageInput, setImageInput] = useState("");
  const [imageError, setImageError] = useState(true);
  const authToken = localStorage.getItem("token");
  const [ageGroup, setAgeGroup] = useState("");

  const handleChangecountry = (e) => {
    const selectedOptions = Array.from(e.target.selectedOptions);
    const selectedValues = selectedOptions.map(option => option.value);

    setFormData({
      ...formData,
      country: selectedValues, 
    });

    if (selectedValues.length === 0) {
      setErrors({
        ...errors,
        country: 'Please select at least one country.',
      });
    } else {
      setErrors({
        ...errors,
        country: '', 
      });
    }
  };

  const handleRemoveCountry = (countryId) => {
    setFormData({
      ...formData,
      country: formData.country.filter(id => id !== countryId),
    });
  };
  


  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleStatusChange = (e) => {
    setFormData({ ...formData, status: e.target.checked });
  };

  const handleQuillChange = (value) => {
    setQuillInput({ ...quillInput, description: value });
  };

  const handleModeChange = (e) => {
    setFormData({ ...formData, Mode: e.target.value });
  };

  const handleAgeGroupChange = (e) => {
    setFormData({ ...formData, ageGroup: e.target.value });
  };

  const handleMinAgeChange = (e) => {
    setFormData({ ...formData, min_Age: e.target.value });
  };

  const handleMaxAgeChange = (e) => {
    setFormData({ ...formData, max_Age: e.target.value });
  };
  const imageChange = (e) => {
    const selectedFile = e.target.files[0];
    const allowedTypes = ["image/jpeg", "image/jpg", "image/png"];
    const maxSize = 2 * 1024 * 1024; // 2 MB

    if (selectedFile) {
      if (!allowedTypes.includes(selectedFile.type)) {
        setErrors({ imageInput: "Only JPG, JPEG, and PNG files are allowed." });
        setImageError(false);
        return;
      }

      if (selectedFile.size > maxSize) {
        setErrors({ imageInput: "File size should not exceed 2 MB." });
        setImageError(false);
        return;
      }

      setFile(selectedFile);
      setImageInput(URL.createObjectURL(selectedFile));
      setFormData({ ...formData, imageInput: selectedFile.name });
      setImageError(true);
    }
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/editCompetitionDetailsForAge/${id}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );
      console.log('editDetail',response);
      setBanner(`${BACKEND_URL}/storage/images/${response.data[0].image}`);
      setFormData({
        comp_date: response.data[0].comp_date,
        title: response.data[0].title,
        c_id: response.data[0].c_id,
        competition: response.data[0].id,
        status: response.data[0].status,
        link: response.data[0].link,
        Mode: response.data[0].mode,
        country: response.data[0].country,
        ageGroup: response.data[0].age_group,
        min_Age: response.data[0].min_age,
        max_Age: response.data[0].max_age,
      });

      if (response.data[0].description.trim() !== '') {
        setQuillInput({ description: response.data[0].description ?? '' });
      }

      setImageInput(`${BACKEND_URL}/storage/images/${response.data[0].image}`);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [id]);

  const validateFormData = () => {
    let errors = {};

    if (!formData.comp_date?.trim()) {
      errors.comp_date = "Date is required";
    }
    if (!formData.title?.trim()) {
      errors.title = "Title is required";
    }
    if (!quillInput.description?.trim() || quillInput.description === '<p><br></p>') {
      errors.description = "Description is required";
    }
    if (!formData.link?.trim()) {
      errors.link = "Link is required";
    }
    
    if (!formData.ageGroup?.trim()) {
      errors.ageGroup = "Age Group is required";
    }

    if (!imageError) {
      errors.imageInput = "Invalid image file";
    }

    if (!formData.min_Age?.trim()) {
      errors.min_Age = "Min Age Group is required";
    }
    if (!formData.max_Age?.trim()) {
      errors.max_Age = "Max Age Group is required";
    }

    setErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateFormData()) {
      const allData = {
        ...formData,
        quillInput,
        status: formData.status ? "true" : "false",
      };
      const newFormData = new FormData();
      newFormData.append("allData", JSON.stringify(allData));
      newFormData.append("quillInput", JSON.stringify(quillInput));
      if (file) {
        newFormData.append("imageInput", file);
      }

      try {
        const response = await axios.post(
          `${API_URL}/editCompetitionDetailsForAge/${id}`,
          newFormData,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );

        console.log('editdata',response);
        toast.success(response.data.message);
        navigate("/admin/competition-details");
      } catch (error) {
        if (error.response && error.response.data.error) {
          setErrors(error.response.data.error);
        } else {
          console.error("Error:", error);
        }
      }
    }
  };

  const fetchCompetition = async () => {
    try {
      const [compResponse, countryResponse] = await Promise.all([
        axios.get(`${API_URL}/selectCompetition`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }),
        axios.get(`${API_URL}/countries`, {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }),
      ]);

      setComplist(compResponse.data);
      setCountryList(countryResponse.data);
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  useEffect(() => {
    fetchCompetition();
  }, []);

  return (
    <>
      <div>
        <div className="layout-wrapper layout-content-navbar">
          <div className="layout-container">
            <div className="layout-page">
              <div className="content-wrapper" style={{ display: "inline-block" }}>
                <ToastContainer />

                <div className="col-12 px-4 mt-4">
                  <div className="card mb-4">
                    <div className="card-body">
                      <div className="container-xxl flex-grow-1 container-p-y">
                        <h4>
                          <span className="fw-light text-dark">Competition Details</span>
                          <h6 className="text-muted">Fill Banner Competition</h6>
                        </h4>
                      </div>
                      <form onSubmit={handleSubmit} method="post">
                        <div className="row">
                          <div className="col-4">
                            <label className="form-label">
                              Select Category<span style={{ color: "red" }}>*</span>
                            </label>
                            <select
                              className="form-control"
                              name="competition"
                              onChange={handleChange}
                              value={formData.competition || ""}
                            >
                              <option value="" disabled>Select Category</option>
                              {complist.map((item, index) => (
                                <option key={index} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                            {errors.competition && (
                              <div className="error text-danger">
                                {errors.competition}
                              </div>
                            )}
                          </div>
                          <div className="col-4">
                            <label className="form-label">
                              Choose Date<span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              className="form-control"
                              type="date"
                              name="comp_date"
                              value={formData.comp_date || ""}
                              onChange={handleChange}
                            />
                            {errors.comp_date && (
                              <div className="error text-danger">
                                {errors.comp_date}
                              </div>
                            )}
                          </div>
                          <div className="col-4">
                            <label htmlFor="formFile" className="form-label">
                              Upload Image<span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              className="form-control"
                              type="file"
                              id="formFile"
                              name="image"
                              onChange={imageChange}
                            />
                            <div className="text-muted">
                              Allowed JPG, JPEG or PNG. Max size of 2 MB
                            </div>
                            {errors.imageInput && (
                              <div className="error text-danger">
                                {errors.imageInput}
                              </div>
                            )}
                            {imageInput && (
                              <img
                                src={imageInput}
                                alt="Preview"
                                className="img-fluid mt-2"
                                style={{ maxWidth: "100px" }}
                              />
                            )}
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-6">
                            <label className="form-label">
                              Title<span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="title"
                              value={formData.title || ""}
                              onChange={handleChange}
                            />
                            {errors.title && (
                              <div className="error text-danger">
                                {errors.title}
                              </div>
                            )}
                          </div>

                          <div className="col-6">
                            <label className="form-label">
                              Country<span style={{ color: "red" }}>*</span>
                            </label>
                            <select
                              className="form-control"
                              name="country"
                              value={formData.country || ""}
                              onChange={handleChange}
                            >
                              <option value="" disabled>Select Country</option>
                              {countryList.map((item, index) => (
                                <option key={index} value={item.id}>
                                  {item.name}
                                </option>
                              ))}
                            </select>
                            {errors.country && (
                              <div className="error text-danger">
                                {errors.country}
                              </div>
                            )}
                          </div>

                          <div className="col-6">
                            <label className="form-label">
                              Link<span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="link"
                              value={formData.link || ""}
                              onChange={handleChange}
                            />
                            {errors.link && (
                              <div className="error text-danger">
                                {errors.link}
                              </div>
                            )}
                          </div>

                          <div className="col-6">
                            <label className="form-label">
                              Mode<span style={{ color: "red" }}>*</span>
                            </label>
                            <select
                              className="form-control"
                              name="Mode"
                              value={formData.Mode || ""}
                              onChange={handleModeChange}
                            >
                              <option value="" disabled>Select Mode</option>
                              <option value="3">Online/Onsite</option>
                              <option value="1">Online</option>
                              <option value="2">Onsite</option>
                            </select>
                            {errors.Mode && (
                              <div className="error text-danger">
                                {errors.Mode}
                              </div>
                            )}
                          </div>

                          <div className="col-6">
                            <label className="form-label">
                              Age Group<span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                            className="form-control"
                            type="text"
                            name="ageGroup"
                            onChange={handleAgeGroupChange}
                            value={formData.ageGroup}
                            placeholder="Enter age group (e.g., 4 to 7)"
                            />
                            {errors.ageGroup && (
                              <div className="error text-danger">
                                {errors.ageGroup}
                              </div>
                            )}
                          </div>
                          <div className="col-3">
                            <label className="form-label">
                              Min age<span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="min_Age"
                              value={formData.min_Age} 
                              onChange={handleMinAgeChange}
                             
                            />
                            {errors.min_Age && (
                              <div className="error text-danger">
                                {errors.min_Age}
                              </div>
                            )}
                          </div>
                          <div className="col-3">
                           
                             <label className="form-label">
                              Max age<span style={{ color: "red" }}>*</span>
                            </label>
                            <input
                              className="form-control"
                              type="text"
                              name="max_Age"
                              value={formData.max_Age}
                              onChange={handleMaxAgeChange}
                              
                            />
                             {errors.max_Age && (
                              <div className="error text-danger">
                                {errors.max_Age}
                              </div>
                            )}
                          </div>

                          <div className="col-12">
                            <label className="form-label">
                              Description<span style={{ color: "red" }}>*</span>
                            </label>
                            <ReactQuill
                              value={quillInput.description}
                              onChange={handleQuillChange}
                            />
                            {errors.description && (
                              <div className="error text-danger">
                                {errors.description}
                              </div>
                            )}
                          </div>

                          <div className="col-12 mt-4">
                            <div className="form-check form-switch">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="status"
                                name="status"
                                checked={formData.status || false}
                                onChange={handleStatusChange}
                              />
                              <label className="form-check-label" htmlFor="status">
                                Active
                              </label>
                            </div>
                          </div>

                         
                          <div className="text-end mt-4">
                          <Button type="submit" text="Save" />
                        </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
